import {PLACES} from '../containers/MainMenu/constants';
import {
  GET_PLACES,
  SELECT_PLACE,
  SET_CURRENT_COMPANY_UID_PLACES,
  SET_FIRST_PAGE_PLACES,
  SET_NUMBER_PAGE_PLACES,
  SET_CURRENT_SEARCH_TERM_PLACES
} from '../containers/ListPlaces/constants';

import {normalize, schema} from 'normalizr';

const initialState = {
  data: [],
  next: null,
  isFetching: false,
  isFetchingPlace: false,
  place: null,
  currentPlaceUid: null,
  entities: [],
  results: []
};

const placeSchema = new schema.Entity('places', {}, {idAttribute: 'uid'});
const placeListSchema = new schema.Array(placeSchema);

export default function reducer(state = initialState, action) {
  const {payload} = action;
  let data;
  switch (action.type) {
    case SET_CURRENT_SEARCH_TERM_PLACES:
      return {
        ...state,
        searchTerm: action.term
      }
    case SET_CURRENT_COMPANY_UID_PLACES:
      return {
        ...state,
        currentCompanyUid: action.uid
      }
    case SET_FIRST_PAGE_PLACES:
      return {
        ...state,
        firstList: action.list
      }
    case SET_NUMBER_PAGE_PLACES:
      console.log(action.currentPageNumber)
      return {
        ...state,
        currentPageNumber: action.currentPageNumber
      }
    case SELECT_PLACE:
      if(state.placeUid !== action.placeUid){
        localStorage.setItem('placeIdRedeem', action.placeUid);
        localStorage.setItem("placeAddressRedeem", action.placeAddress)
        localStorage.setItem("placeNameRedeem", action.placeName)
        console.log(action, "action")
        return {
          ...state,
          currentPlaceUid: action.placeUid,
        };
      }else{
        return {
          ...state
        }
      }
    case `${PLACES}_PENDING`:
      return {...state,
        isFetching: true,
        next: null,
        listStatus: "loading",
        count: 0,
        entities: null,
        nextPage: null,
        previousPage: null,
        results: []
      };
    case `${PLACES}_REJECTED`:
      return {...state, isFetching: false, error: action.payload};
    case `${PLACES}_FULFILLED`:
      data = (payload && payload.status === 200) ? normalize(payload.data.results, placeListSchema) : {};
      return Object.assign({},
        state, {
          isFetching: false,
          data: payload && payload.data.results,
            entities: data.entities ? data.entities.places : [],
            results: data.result ? data.result : [],
            nextPage: payload && payload.data ? payload.data.next : null,
            previousPage: payload && payload.data ? payload.data.previous : null,
            listStatus: payload && payload.status === 200 ? "success" : "error",
            count: payload && payload.data ? payload.data.count : 0,
        }
      );
    case `${GET_PLACES}_PENDING`:
      return {
        ...state,
        listStatus: "loading",
        count: 0,
        entities: null,
        nextPage: null,
        previousPage: null,
        results: []
      };
    case `${GET_PLACES}_FULFILLED`:
      data = (payload && payload.status === 200) ? normalize(payload.data.results, placeListSchema) : {};
      return {
        ...state,
        entities: data.entities ? data.entities.places : [],
        results: data.result ? data.result : [],
        nextPage: payload && payload.data ? payload.data.next : null,
        previousPage: payload && payload.data ? payload.data.previous : null,
        listStatus: payload && payload.status === 200 ? "success" : "error",
        count: payload && payload.data ? payload.data.count : 0,
      };


    default:
      return state;
  }
}
