import React, {Component, Suspense} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingIndicator from "../../components/LoadingIndicator";
import {searchBusiness} from "../Login/actions";
import {Whitelabel} from "../../utils/generic";
import history from "../../utils/history";
import {toast, ToastContainer} from "react-toastify";
import {withTranslation} from "react-i18next";
import i18n from "../../components/i18n";


// Lazy load
const Promotions = React.lazy(() => import('../Promotions'));
const MainMenu = React.lazy(() => import('../MainMenu'));
const History = React.lazy(() => import('../History'));
const ProductsRedeemables = React.lazy(() => import('../ProductsRedeemables'));
const CreateUser = React.lazy(() => import('../CreateUser'));
const Orders = React.lazy(() => import('../Orders/Orders'));
const ListPlaces = React.lazy(() => import('../ListPlaces'));
const PromotionTransferred = React.lazy(() => import('../Transferred/PromotionTransferred'));

class Bederr extends Component {
   constructor(props) {
      super(props);
      this.state = {
         lang: "es",
      };
   }

   componentDidMount() {
      let {business} = this.props;
      let subdomain = window.localStorage.getItem(`subdomain`);


      toast.configure();
      if (business == null)
         if (subdomain !== undefined && subdomain !== null && subdomain.length > 0)
            this.props.dispatch(searchBusiness(subdomain))
         else
            this.closeSession()
   }



   componentDidUpdate() {
      console.log(this.state.lang, localStorage.getItem("lang"))
      if(this.state.lang!==localStorage.getItem("lang")){
         this.setState({lang: localStorage.getItem("lang")})
         i18n.changeLanguage(localStorage.getItem("lang")); // Cambia el idioma global
      }
   }


   componentWillReceiveProps(nextProps, nextContext) {
      const { business } = nextProps;
      if (business != null) {
         window.localStorage.setItem(`apiKey`, business.api_key);
         window.localStorage.setItem(`subdomain`, business.domain.subdomain);
      }else{
         this.closeSession()
      }
   }

   closeSession =  () => {
      const NAME = Whitelabel.name;
      window.localStorage.removeItem(`token${NAME}`);
      window.localStorage.removeItem('placeIdRedeem');
      window.localStorage.removeItem('companyIdRedeem');
      window.localStorage.removeItem('subdomain');
      document.location.href = '/';
   };

   render() {
      let {business, t} = this.props;
      if (business == null)
         return (<LoadingIndicator/>)
      return (
          <Suspense fallback={<div>Cargando...</div>}>
             <div>
                <div className="content">
                   <div id="header" className="main-header flex-column flex-md-row sticky-top">
                      <MainMenu history={history}/>
                   </div>

                   <div className="container-fluid" style={{minWidth: "400px"}}>
                      <Suspense fallback={<div>{t("bederr:loading")}</div>}>
                         <Switch>
                            <Route exact path="/" component={Promotions}/>
                            <Route exact path="/places" component={ListPlaces}/>
                            <Route exact path="/historial" component={History}/>
                            <Route exact path="/products-redeemables" component={ProductsRedeemables}/>
                            <Route exact path="/orders" component={Orders}/>
                            <Route exact path="/transferred" component={PromotionTransferred}/>
                            <Route exact path="/createuser" component={CreateUser}/>
                            <Route exact path="/historial/:page" component={History}/>
                         </Switch>
                      </Suspense>
                   </div>
                </div>

                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                />
             </div>
          </Suspense>
      );
   }
}

Bederr.propTypes = {
   dispatch: PropTypes.func,
   match: PropTypes.object,
   business: PropTypes.object,
};
History.defaultProps = {
   match: null,
   business: null,
};

export default connect(({user}) => ({
   business: user.business
}))(withTranslation()(Bederr));

