import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Router, Switch } from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import reducer from "./reducersapp";
import promise from 'redux-promise-middleware';

import './styles/theme.scss';
import history from "./utils/history";
import AuthRoute from "./components/AuthRoute/AuthRoute";
import Login from "./containers/Login/Login";
import Bederr from "./containers/Bederr/Bederr";
import AuthorizedRoute from "./components/AuthorizedRoute/AuthorizedRoute";
import StagingLabel from "./components/StagingLabel/StagingLabel";
import './components/i18n';
import LanguageSelector from "./components/LanguageSelector/LanguageSelector";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/i18n";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Tooltip, Toast, Popover } from 'bootstrap';
window.BootstrapTooltip = Tooltip;
window.BootstrapToast = Toast;
window.BootstrapPopover = Popover;

const middlewares = [];

const store = createStore(
    reducer,
    applyMiddleware(promise, thunkMiddleware, ...middlewares),
);

function App() {

    useEffect(() => {
        // Forzar inicialización de Bootstrap cuando se monta el componente
        import("bootstrap/dist/js/bootstrap.bundle.min.js").then(() => {
            console.log(" ");
        });

        // Escuchar cambios de ruta y reinicializar Bootstrap
        const unlisten = history.listen(() => {
            import("bootstrap/dist/js/bootstrap.bundle.min.js").then(() => {
                console.log(" ");
            });
        });

        return () => {
            unlisten(); // Cleanup cuando el componente se desmonta
        };
    }, []);

    return (
        <Fragment>
            {process.env.REACT_APP_URL_REST === "https://api.bemaven.xyz/" && <StagingLabel />}
            <LanguageSelector/>

            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <Router history={history}>
                        <Switch>
                            <AuthRoute exact path="/login" component={Login} />
                            <AuthorizedRoute path="/" component={Bederr} />
                            <Redirect to="/login" />
                        </Switch>
                    </Router>
                </Provider>
            </I18nextProvider>
        </Fragment>
    );
}

export default App;
