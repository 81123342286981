import {
    CLEAN_UPDATE_STOCK,
    GET_PRODUCTS_REDEEMABLES, UPDATE_STOCK,
} from '../containers/ProductsRedeemables/constants';
import {normalize, schema} from 'normalizr';
import {STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS} from "../containers/Bederr/constants";

const promotionSchema = new schema.Entity('promotions', {}, {idAttribute: 'uid'});
const promotionListSchema = new schema.Array(promotionSchema);


const initialState = {
  data: null,
  formInitial: null,
  next: null,
  isFetching: false,
  isFetchingFormInitial: false,
  redemption: null,
  count: 0,
  error: null,
  promotion: null,
  message: '',
  errorApplied: null,
  isFetchingApplied: false,
  errorAppliedBoc: null,
  isFetchingAppliedBoc: false,
  isStamping: false,
  errorStamp: '',
  applyCode: 0,
  applyCodeBoc: 0,
  report: null,
  isFetchingMore: false,
    entities: null,
    results: [],
    previous: null,
    listStatus: null,
};

export default function reducer(state = initialState, action) {
    const {type, payload} = action;
    let data;
    if (type.endsWith('_FULFILLED') && payload && payload.status === 401) {
        //localStorage.removeItem('token');
        localStorage.clear();
        return {
            ...state,
            token: null,
        };
    }
  switch (action.type) {

    case `${GET_PRODUCTS_REDEEMABLES}_PENDING`:
      return { ...state, listProductsStatus: STATUS_LOADING, nextProductsRedeemable: null };
    case `${GET_PRODUCTS_REDEEMABLES}_REJECTED`:
      return { ...state, listProductsStatus: STATUS_ERROR, error: STATUS_ERROR };
    case `${GET_PRODUCTS_REDEEMABLES}_FULFILLED`: {
        data = payload && payload.status === 200 ? normalize(payload.data.results, promotionListSchema) : {};
        return {
            ...state,
            entities: data.entities ? data.entities.promotions : [],
            results: data.result ? data.result : [],
            next: payload && payload.data ? payload.data.next : null,
            previous: payload && payload && payload.data ? payload.data.previous : null,
            listStatus: payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
            count: payload && payload.data ? payload.data.count : 0,
        };
    }
      case `${UPDATE_STOCK}_FULFILLED`:
          data = payload && payload.status === 200 ? payload.data : null;
          let listEntities = state.entities

          const url = payload.config.url;
          const uid = url.split("products/")[1]
          if (listEntities[uid]) {
              listEntities[uid].stock = data.quantity;
          }
          return {
              ...state,
              entities: listEntities,
              updateStatus: payload && payload.status === 200 ? STATUS_SUCCESS : STATUS_ERROR,
          }

      case CLEAN_UPDATE_STOCK:
          return {
              ...state,
              updateStatus: null
          };
    default:
      return state;
  }
}
