import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



let version = "1.0.1"

if(localStorage.getItem("version")){
    if(version!==localStorage.getItem("version")){
        localStorage.clear()
        localStorage.setItem("version", version)
    }
}else{
    localStorage.clear()
    localStorage.setItem("version", version)
}

ReactDOM.render(
    <Fragment>
        {
            localStorage.getItem("version") && version === localStorage.getItem("version") &&
            <App/>
        }
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </Fragment>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
